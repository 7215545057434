<template>
  <div class="container-base section-container-padding" :class="loadingStatus ? 'cursor-wait' : ''">
    <icon-page-header :icon="icon" :title="$t('ingestionProject.header.title')"/>

    <b-row class="step-background">
      <b-col cols="12">
        <b-row>
          <div :class="currentStep >= 1 ? 'ingestion-projects-step-active-color' : 'ingestion-projects-step-disabled-color'"
          class="new-ingestion-proyect-steps-container openSans-400 fz-14">
            <b-icon-check v-if="currentStep > 1" class="circle-step circle-step-check m-3"></b-icon-check>
            <p v-if="currentStep === 1" class="circle-step m-3">1</p>
            <p class="align-group m-3">{{ $t('ingestionProject.step.one') }}</p>
          </div>
          <div :class="currentStep > 1 ? 'ingestion-projects-step-active-color' : 'ingestion-projects-step-disabled-color'"
          class="new-ingestion-proyect-steps-container openSans-400 fz-14">
            <h3 class="section-title-right-line  openSans-100 fz-23"></h3>
            <b-icon-check v-if="currentStep > 2" class="circle-step circle-step-check m-3"></b-icon-check>
            <p v-if="currentStep <= 2" class="circle-step m-3">2</p>
            <p class="align-group m-3">{{ $t('ingestionProject.step.two') }}</p>
          </div>
          <div :class="currentStep > 2 ? 'ingestion-projects-step-active-color' : 'ingestion-projects-step-disabled-color'"
          class="new-ingestion-proyect-steps-container openSans-400 fz-14">
            <h3 class="section-title-right-line  openSans-100 fz-23"></h3>
            <p class="circle-step m-3">3</p>
            <p class="align-group m-3">{{ $t('ingestionProject.step.three') }}</p>

          </div>
        </b-row>
      </b-col>
    </b-row>

    <UploadFilesSteps
      :project="newIngestionProject"
      :creation-mode="true"
      :ingestion-sources="sources"
      :save-project-callback="startLoadingStatus"
      @update-step="updateStep">
    </UploadFilesSteps>

  </div>
</template>

<script>
import IconPageHeader from '@/modules/common/components/IconPageHeader.vue'
import UploadFilesSteps from '@/modules/projects/views/UploadFilesSteps.vue'

import IngestionService from '@/modules/common/services/IngestionService.js'
import download from 'downloadjs'

export default {

  name: "NewIngestionProject",

  data () {
    return {
      icon: 'icon_createingestionproyect.svg',
      currentStep: 1,
      loadingStatus: false,
      newIngestionProject: {
        fileName: '',
        projectId: '',
        botName: '',
        name: null
      },
      sources: [
        { id: 'upload-file', title: 'ingestionProject.cards.card.uploadFile.title', buttonLabel: 'ingestionProject.cards.chooseFile', available: true, icon: 'icon_cip_upload.svg' },
        { id: 'url', title: 'ingestionProject.cards.card.url.title', buttonLabel: 'ingestionProject.cards.continue', available: true, icon: 'icon_cip_url.svg' },
        { id: 'api', title: 'ingestionProject.cards.card.api.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_api.svg' },
        { id: 'audio', title: 'ingestionProject.cards.card.audio.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_audio.svg' },
        { id: 'youtube-vimeo', title: 'ingestionProject.cards.card.youtubeVimeo', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_youtube.svg' },
        { id: 'aws', title: 'ingestionProject.cards.card.aws.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_aws.svg' },
        { id: 'dropbox', title: 'ingestionProject.cards.card.dropbox.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_dropbox.svg' },
        { id: 'microsoft-azure', title: 'ingestionProject.cards.card.microsoftAzure.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_azure.svg' },
        { id: 'google-drive', title: 'ingestionProject.cards.card.googleDrive.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_drive.svg' },
        { id: 'veeva', title: 'ingestionProject.cards.card.veeva.title', buttonLabel: 'ingestionProject.cards.continue', available: false, icon: 'icon_cip_veeva.svg' }
      ]
    }
  },
  components: {
    IconPageHeader, UploadFilesSteps
  },
  methods: {
    downloadFile() {
      IngestionService.downloadFile(
        this.newIngestionProject.id, this.newIngestionProject.fileName).then((response) => {
          const content = response.headers['content-type'];
          download(response.data, this.newIngestionProject.fileName, content)
      })
    },
    updateStep(step) {
      this.currentStep = step
    },
    startLoadingStatus() {
      this.loadingStatus = true
    }
  }
}
</script>

<style scoped>

.circle-step-check{
  padding-top: 2px;
}

h3.section-title-right-line{
  width: 121px;
  margin: auto 34px auto 0px;
}

div.ingestion-projects-step-disabled-color h3.section-title-right-line::after{
  background: #afafaf;
}

div.ingestion-projects-step-active-color h3.section-title-right-line::after{
  background: #09609f;
}

.circle-step{
  margin-right:0px !important;
}

.ingestion-projects-step-disabled-color p.circle-step {
  color: #5e5e5e;
  border: 2px solid #5e5e5e;
}

p.align-group.m-3{
    margin-left: 12px !important;
    margin-right: 48px !important;
}

.btn-width {
  width: 215px;
}

.align-group{
  position: relative;
  padding-top: 6px;
}

div.row.step-background{
  margin: 0px -35px !important;
}

div.new-ingestion-proyect-steps-container:first-of-type{
  margin-left: auto;
}

div.new-ingestion-proyect-steps-container:last-of-type{
  margin-right: auto;
}

</style>
